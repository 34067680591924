@import "src/styles/helpers";

.section {
  padding-top: 120px;
  padding-bottom: 120px;

  @include media(mobile) {
    padding-top: var(--margin-xxl);
    padding-bottom: var(--margin-xxl);
  }
}

.title {
  text-align: center;
  margin-bottom: var(--margin-md);
}

@keyframes cardFadeIn {
  from {
    opacity: 0.3;
    bottom: -30px;
  }
  to {
    opacity: 1;
    bottom: 0;
  }
}

.card {
  width: 246px;
  flex-shrink: 0;
  text-align: center;
  padding-bottom: var(--margin-sm);
  position: relative;
  opacity: 0.3;

  &.animate {
    animation: cardFadeIn 0.8s ease forwards;
  }

  &__photo {
    position: relative;
    height: 280px;
    width: 100%;

    .cardImage {
      opacity: 0;

      &_visible {
        opacity: 1;
      }
    }

    @keyframes shine {
      to {
        background-position-x: -20%;
      }
    }

    .skeleton {
      width: 100%;
      height: 100%;
      background: linear-gradient(110deg, var(--dark-line-low-accent) 30%, var(--dark-line-accent) 50%, var(--dark-line-low-accent) 70%);
      background-size: 200% 100%;
      animation: 1s shine linear infinite;
      background-position-x: 180%;
    }
  }

  &__title {
    font-family: 'KharkivTone', sans-serif;
    font-size: 16px;
    line-height: 24px;
    margin: 32px 0 4px;
  }

  &__text {
    @include setProperty(color, var(--secondary-200), var(--secondary-300));
  }
}

.subtitle {
  text-align: center;
}

.team {
  margin-top: 56px;

  @include media(mobile) {
    @include hideScrollbar;
    display: flex;
    gap: var(--margin-sm);
    overflow-x: auto;
    overflow-y: hidden;
    margin-right: calc(var(--container-padding-mobile) * -1);
    padding-right: var(--container-padding-mobile);
    margin-left: calc(var(--container-padding-mobile) * -1);
    padding-left: var(--container-padding-mobile);
  }

  @include media(portrait) {
    display: grid;
    grid-template-columns: repeat(2, 246px);
    gap: 36px;
    align-items: flex-start;
    justify-content: center;
  }

  @include media(landscape) {
    display: grid;
    grid-template-columns:  repeat(3, 246px);
    gap: 24px;
    align-items: flex-start;
    justify-content: center;
  }

  @include media(desktopAll) {
    display: grid;
    grid-template-columns: repeat(4, 246px);
    gap: 24px;
    align-items: flex-start;
    justify-content: center;
  }
}

.moreButton {
  border: 1px solid;
  padding: 31px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  @include setProperty(border-color, var(--primary-500), var(--primary-900));
  @include setProperty(color, var(--primary-500), var(--primary-900));
  width: 246px;
  height: 215px;


  .icon {
    margin-bottom: 30px;
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    @include setProperty(color, var(--primary-500), var(--primary-900));

    svg {
      height: 100%;
      width: 100%;
    }
  }

  @include media(mobile) {
    order: 1;
  }

  &__title {
    font-family: 'KharkivTone', sans-serif;
    font-size: 24px;
    line-height: 32px;
  }

  &:hover {
    @include setProperty(border-color, var(--primary-300), var(--primary-700));
    @include setProperty(color, var(--primary-300), var(--primary-700));
  }
}

.column {
  display: grid;
  justify-content: center;
  gap: var(--margin-sm);
  width: 246px;

  &_first {

    @include media(portrait) {
      .card {
        order: 1;
      }

      .moreButton {
        margin-bottom: 80px;
      }
    }
  }

  &_second {

    @include media(landscape) {
      .card:first-of-type {
        margin-top: 200px;
      }
    }

    @include media(desktopAll) {
      .card:first-of-type {
        margin-top: 120px;
      }
    }
  }

  &_third {

    @include media(desktopAll) {
      padding-top: 280px;
    }
  }

  &_last {

    @include media(portrait) {
      gap: 80px;

      .card:first-of-type {
        margin-top: 160px;
      }

      .moreButton {
        order: 1;
      }
    }

    @include media(landscape) {
      .moreButton {
        margin-top: 90px;
        margin-bottom: 70px;
      }

      .card:first-child {
        margin-top: calc(215px + 90px + 70px + 16px);
      }
    }

    @include media(desktopAll) {
      .moreButton {
        margin-top: 90px;
        margin-bottom: 70px;
      }

      .card:first-child {
        margin-top: calc(215px + 90px + 70px + 16px);
      }
    }
  }
}
