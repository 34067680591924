@import "src/styles/helpers";

.section {
  padding-top: var(--margin-xxl);
  padding-bottom: var(--margin-xxl);

  @include media(tablet) {
    padding-top: 104px;
    padding-bottom: 104px;
  }

  @include media(landscape) {
    display: grid;
    grid-template-columns: 1fr 400px;
    gap: 100px;
  }

  @include media(desktopAll) {
    display: grid;
    grid-template-columns: 1fr 400px;
    gap: 100px;
    padding-top: 120px;
    padding-bottom: 120px;
  }
}

.title {
  margin-bottom: 40px;

  @include media(landscape) {
    margin-bottom: 80px;
  }

  @include media(desktopAll) {
    margin-bottom: 80px;
  }
}

.photo {
  position: relative;
  @include imgCover;

  &_first {
    height: 380px;

    @include media(mobile) {
      margin-bottom: 40px;
    }

    @include media(portrait) {
      margin-bottom: 40px;
    }
  }

  &_second {
    height: 320px;
  }
}

.text {
  margin-bottom: 40px;

  @include media(desktopAll) {
    font-size: 14px;
  }
}

.column {

  &:last-child {

    @include media(desktopAll) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}

.believe {
  margin-top: 40px;

  @include media(portrait) {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: var(--margin-lg);
  }

  &__title {
    margin-bottom: var(--margin-lg);
    font-family: 'KharkivTone', sans-serif;
    font-size: 20px;
    line-height: 28px;

    @include media(landscape) {
      font-size: 24px;
      line-height: 32px;
    }

    @include media(desktopAll) {
      font-size: 24px;
      line-height: 32px;
    }
  }

  &__content {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    gap: 10px;

    @include media(mobile) {
      max-width: 400px;
      margin-left: auto;
    }

    @include media(portrait) {
      max-width: 500px;
      margin-left: auto;
    }

    @include media(landscape) {
      max-width: 400px;
      margin-left: auto;
    }

    @include media(desktopAll) {
      max-width: 400px;
      margin-left: auto;
    }
  }
}

.believeItemValue {
  @include setProperty(background, var(--secondary-600), var(--secondary-50));
  padding: 8px 16px;
  font-family: 'KharkivTone', sans-serif;
  font-size: 14px;
  line-height: 24px;
  border-radius: 4px;
  display: inline-flex;
  height: 40px;

  &:first-of-type {
    border-top-left-radius: 20px;
  }

  &:nth-of-type(5) {

    @include media(portrait) {
      border-bottom-left-radius: 20px;
    }
  }

  &:nth-of-type(3) {

    @include media(landscape) {
      border-top-right-radius: 20px;
    }

    @include media(desktopAll) {
      border-top-right-radius: 20px;
    }
  }
}

.believeItemSymbol {
  border: 1px solid;
  @include setProperty(border-color, var(--secondary-400), var(--secondary-100));
  @include setProperty(color, var(--secondary-300), var(--secondary-100));
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  border-radius: 4px;

  svg {
    width: 14px;
    height: 14px;
  }
}

.believeItemFinish {
  @extend .believeItemValue;
  display: inline-block;
  margin-right: 0;
  @include setProperty(background, var(--primary-500), var(--primary-900));
  @include setProperty(color, var(--dark-bg), var(--light-bg));
  border-bottom-right-radius: 20px;
}
