@import "src/styles/helpers";

.section {
  padding-top: var(--margin-xxl);
  padding-bottom: var(--margin-xxl);
  text-align: center;
  position: relative;
  max-width: 100vw;
  overflow: hidden;

  @include media(tablet) {
    padding-top: 104px;
    padding-bottom: 104px;
  }

  @include media(desktopAll) {
    padding-top: 120px;
    padding-bottom: 120px;
  }
}

.title {
  font-family: 'KharkivTone', sans-serif;
  font-size: 24px;
  line-height: 32px;

  @include media(tablet) {
    font-size: 28px;
    line-height: 36px;
  }

  @include media(desktopAll) {
    font-size: 40px;
    line-height: 48px;
  }

  @include media(desktopSmall) {
    font-size: 28px;
    line-height: 36px;
    max-width: 720px;
    margin: 0 auto;
  }
}

.button {
  margin: 0 auto;
  height: 48px;

  @include media(mobile) {
    width: 100% !important;
  }
}

.text {
  margin: 40px auto;
  max-width: 455px;

  @include media(tablet) {
    font-size: 16px;
  }
}

.ellipse {
  position: absolute;
  z-index: 0;
  pointer-events: none;
  width: 532px;
  height: 65px;
  filter: blur(71.5px);
  opacity: 0.4;
  left: 20%;
  bottom: 190px;
  transform: rotate(11.566deg);
  transform-origin: left top;

  @include media(desktopAll) {
    left: 35%;
  }
}
