@import "src/styles/helpers";

$column-width: 140px;

.growth {
  padding-top: var(--margin-xxl);
  padding-bottom: var(--margin-xxl);

  @include media(tablet) {
    padding-top: 104px;
    padding-bottom: 104px;
  }

  @include media(desktopAll) {
    padding-top: 120px;
    padding-bottom: 120px;
  }

  &__content {
    gap: var(--margin-xs);
    display: grid;

    @include media(portrait) {
      display: grid;
    }

    @include media(landscape) {
      display: flex;
    }

    @include media(desktopAll) {
      display: flex;
    }
  }
}

.title {
  margin-bottom: var(--margin-xxl);
}

.card {
  position: relative;
  @include setProperty(background, var(--secondary-900), linear-gradient(76deg, rgba(229, 229, 229, 0.10) 0%, #F5F5F5 99.03%));
  overflow: hidden;
  display: block;
  width: 100%;
  @include setProperty(color, var(--secondary-50), var(--secondary-500));
  transition: all 500ms ease-in-out;
  padding: 44px 24px;
  border: 1px solid;
  @include setProperty(border-color, transparent, rgba(0, 0, 0, 0.05));

  @include media(tablet) {
    padding: 49px 48px;
  }

  @include media(landscape) {
    padding: 0;
  }

  @include media(desktopAll) {
    padding: 0;
  }

  .ellipse {
    position: absolute;
    width: 490px;
    height: 54px;
    left: -100px;
    bottom: 0;
    transform: rotate(17deg);
    filter: blur(71.5px);
    transform-origin: right bottom;
    opacity: 0;
    transition: opacity 0.3s ease;
    pointer-events: none;
  }

  @include media(landscape) {
    height: 500px;
    min-width: $column-width;
    flex-basis: $column-width;
    display: flex;
    flex-direction: row-reverse;
    align-items: stretch;
  }

  @include media(desktopAll) {
    height: 500px;
    min-width: $column-width;
    flex-basis: $column-width;
    display: flex;
    flex-direction: row-reverse;
    align-items: stretch;
  }

  &.active {

    .ellipse {
      opacity: 1;
    }

    @include media(landscape) {
      flex-basis: 200em;
    }

    @include media(desktopAll) {
      flex-basis: 200em;
    }
  }

  &:not(.active):hover {
    .card__title {
      @include setProperty(color, var(--secondary-100), var(--secondary-500));
    }
  }

  &__accordeon {

    .card__content {
      margin-top: 32px;

      @include media(portrait) {
        margin-top: var(--margin-xxl);
      }

      @keyframes showContent {
        0% {
          opacity: 0;
        }
        30% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }

      @include media(landscape) {
        margin-top: 0;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;
        opacity: 0;
        transition: opacity 1s ease;

        .active & {
          animation: showContent 1s forwards;
        }
      }

      @include media(desktopAll) {
        margin-top: 0;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;
        opacity: 0;
        transition: opacity 0.3s ease;

        .active & {
          animation: showContent 1s forwards;
        }
      }
    }

    @include media(mobile) {
      max-height: 0;
      overflow: hidden;
      transition: all 250ms ease-in-out;

      .active & {
        transition: all 500ms ease-in-out;
        max-height: 380px;
      }
    }

    @include media(portrait) {
      max-height: 0;
      overflow: hidden;
      transition: all 250ms ease-in-out;

      .active & {
        transition: all 500ms ease-in-out;
        max-height: 380px;
      }
    }

    @include media(landscape) {
      position: absolute;
      right: $column-width;
      height: 100%;
      padding: 49px 0 49px 48px;
    }

    @include media(desktopAll) {
      position: absolute;
      right: $column-width;
      height: 100%;
      padding: 49px 58px;
    }
  }

  &__mainLine {
    position: relative;
    font-size: 20px;
    line-height: 28px;

    @include media(mobile) {
      display: grid;
      grid-template-columns: auto 1fr;
      justify-content: space-between;
      text-align: right;
      align-items: center;
    }

    @include media(portrait) {
      display: grid;
      grid-template-columns: auto 1fr;
      justify-content: space-between;
      text-align: right;
      align-items: center;
      font-size: 24px;
      line-height: 32px;
    }

    @include media(landscape) {
      padding: 49px 48px;
      width: $column-width;
      text-align: center;
    }

    @include media(desktopAll) {
      padding: 49px 24px;
      width: $column-width;
      text-align: center;
    }

    .index {
      @include setProperty(color, var(--primary-500), var(--primary-900));
      font-family: 'KharkivTone', sans-serif;
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 4px;
    }
  }

  &__title {
    font-family: 'KharkivTone', sans-serif;

    @include media(landscape) {
      writing-mode: vertical-rl;
      display: flex;
      align-items: center;
      width: 100%;
      font-size: 24px;
      line-height: 32px;
      @include setProperty(color, var(--secondary-400), var(--secondary-200));
      margin-top: var(--margin-lg);

      .active & {
        @include setProperty(color, var(--secondary-100), var(--secondary-500));
      }
    }

    @include media(desktopAll) {
      writing-mode: vertical-rl;
      display: flex;
      align-items: center;
      width: 100%;
      font-size: 24px;
      line-height: 32px;
      @include setProperty(color, var(--secondary-400), var(--secondary-200));
      margin-top: var(--margin-lg);

      .active & {
        @include setProperty(color, var(--secondary-100), var(--secondary-500));
      }
    }
  }

  &__icon {
    position: relative;
    width: 56px;
    height: 56px;

    @include media(tablet) {
      width: 100px;
      height: 100px;
    }

    @include media(landscape) {
      margin-bottom: auto;
    }

    @include media(desktopAll) {
      margin-bottom: auto;
      width: 100px;
      height: 100px;
    }

    &:not(.card__icon_mobile) {
      @include media(mobile) {
        display: none;
      }

      @include media(portrait) {
        display: none;
      }
    }

    &_mobile {
      @include media(landscape) {
        display: none;
      }

      @include media(desktopAll) {
        display: none;
      }
    }
  }

  &__subtitle {
    font-family: 'KharkivTone', sans-serif;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: var(--margin-md);

    @include media(desktopAll) {
      font-size: 16px;
      line-height: 24px;
    }
  }

  &__text {
    margin-bottom: var(--margin-md);

    @include media(desktopAll) {
      font-size: 14px;
      letter-spacing: var(--ls-183);
    }
  }
}
